import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import {getProjectUrl} from '../services/functions'
import {BASE_URL} from '../services/config';
import {ButtonBack} from '../components/button/button-back';

export const PageSchedule = () => {

   const months:any = [
      {n:1, name:"Jan"},
      {n:2, name:"Feb"},
      {n:3, name:"Mrt"},
      {n:4, name:"Apr"},
      {n:5, name:"Mei"},
      {n:6, name:"Jun"},
      {n:7, name:"Jul"},
      {n:8, name:"Aug"},
      {n:9, name:"Sep"},
      {n:10, name:"Okt"},
      {n:11, name:"Nov"},
      {n:12, name:"Dec"}
   ];
   const dayNames:any = ["Zo", "Ma", "Di", "Wo", "Do", "Vr", "Za"];
   

   const getNext30Days = (getYear:any, getMonth:any) => {
      const dates: Date[] = [];
      
      const startDate = new Date(getYear, getMonth-1, 1);
      const today = new Date();
      var nDays = new Date(getYear, getMonth, 0).getDate();

      
      startDate.setHours(0, 0, 0, 0);
    
      for (let i = 0; i < nDays; i++) {
        const nextDay = new Date(startDate);
        nextDay.setDate(startDate.getDate() + i);
        var timestamp = nextDay.getTime()/1000;
        var day = nextDay.getDate();
        var dayFormatted = day;
        var dayName = nextDay.getDay();
        var dayNameFormatted = dayNames[dayName];
        var month = nextDay.getMonth()+1;
        var monthFormatted = months[month-1].name;
        var year = nextDay.getFullYear();
        var active = false;
        if(year === today.getFullYear() && month === today.getMonth()+1 && day === today.getDay()){
         var active = true;
        }
        var nextDayArray:any = {active:active,timestamp:timestamp, day:day, dayFormatted:dayFormatted, dayName:dayName, dayNameFormatted:dayNameFormatted, month:month, monthFormatted:monthFormatted, year:year};
        dates.push(nextDayArray);
      }
    
      return dates;
    };

   const [dates, setDates] = useState<any>([]);
   const [currentMonth, setCurrentMonth] = useState<any>(7);
   const [currentYear, setCurrentYear] = useState<any>(2024);

   useEffect(() => {
      
   
      const next30Days = getNext30Days(currentYear,currentMonth);
      setDates(next30Days);
   }, []);


   const updateCalendarMonth = (month:any) => {
      setCurrentMonth(month);
      const next30Days = getNext30Days(currentYear,month);
      setDates(next30Days);
      console.log(next30Days);
   }

   const updateCalendarYear = (year:any) => {
      setCurrentYear(year);
      const next30Days = getNext30Days(year,currentMonth);
      setDates(next30Days);
      console.log(next30Days);
   }

 

  
 



  const fetchProjects = async () => {
     
      try {
         const response = await axios.get(BASE_URL+'/projects');
         var results = response.data.results;
         console.log(results);
         setDates(results);
          
      } catch (error) {
          console.error('Failed to fetch organisations:', error);
      }
  };



   

   return (
      <div className="page">
         <section className="title-header">
            <div className="grid">
               <ButtonBack/>
               <h2>Agenda</h2>
            </div>
         </section>
         <section className="schedule">
            <div className="grid">
               <select name="year" value={currentYear} onChange={(e) => updateCalendarYear(e.target.value)}>
                  <option value="2024">2024</option>
               </select>
               <select name="month" value={currentMonth} onChange={(e) => updateCalendarMonth(e.target.value)}>
                  {months.map((month:any, i:any) => (
                     <option key={i} value={month.n}>{month.name}</option>
                  ))}
               </select>
            </div>
            <div className="dates-slider-container">
               <div className="grid">
                  <ul>
                     {dates.map((date: any, i: any) => (
                        <li key={i} className={`${
                           date.active ? '-active' : ''
                       }`}>
                           <span className="dayname">{date.dayNameFormatted}</span>
                           <span className="day">{date.dayFormatted}</span>
                           <span className="month">{date.monthFormatted}</span>
                        </li>
                     ))}
                     
                  </ul>
               </div>
            </div>

            <div className="date-content">
               <div className="grid">
                  <div className="date-content-blocks">
                     <div className="date-content-block">
                        <h3>Project Naam</h3>
                     </div>
                     <div className="date-content-block">
                        <h3>Project Naam</h3>
                     </div>
                  </div>
               </div>
            </div>
            
            
         </section>
      </div>
   );
}



import React from 'react';
import axios from 'axios';
import { useEffect, useRef,useState } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import {BASE_URL} from '../services/config';
import {ButtonBack} from '../components/button/button-back';
//import {getVideoPosterUrl, getVideoPreviewUrl, getVideoUrl,getPornstarPosterUrl, getPornstarUrl} from '../services/functions'
import { tokenDecoded2, tokenGet, tokenValid, authHeader} from '../services/auth';

export const PageProject = () => {
   let navigate: NavigateFunction = useNavigate();
   const { id } = useParams<{ id: string }>();
   const [project, setProject] = useState<any>(false);


   useEffect(() => {
      fetchProject();
  }, []);




  const fetchProject = async () => {
   try {
      const response = await axios.get(`${BASE_URL}/project/`+id, {
         headers: authHeader(),
     });
      var results = response.data.results;
      setProject(results);
       
   } catch (error) {
       console.error('Failed to fetch organisations:', error);
   }
};





   return (
      <div className="page">
         
         <section className="title-header">
            <div className="grid">
               <ButtonBack/>
               <h2>{project.name}</h2>
            </div>
         </section>
      </div>
   );
}




import { useEffect, useRef,useState } from 'react';

import {login} from '../services/auth';

export const PageLogin = () => {

   var defaultFormValues = { username: 'user1', password: 'aaa' };
   const [formValues, setFormValues] = useState<any>(defaultFormValues);

   const updateFormValues = (name: any, value: any) => {
      var formValues2 = formValues;
      formValues2[name] = value;
      setFormValues(formValues2);
   };

   const submitForm = async () => {
      await login(formValues).then(
         (data) => {
            window.dispatchEvent(new Event('loggedIn'));
         },
         (error) => {
            
         
         },
      );
   };



   return (
      
         <section className="login">
            <div className="inner">
               <div className="logo">
                  
               </div>
               <div className="box">
                  <input type="text" placeholder="Gebruikersnaam" onChange={(e) =>
                                            updateFormValues('username', e.target.value)
                                        }/>
                  <input type="password" placeholder="Wachtwoord" onChange={(e) =>
                                            updateFormValues('password', e.target.value)
                                        }/>
                  <div className="button" onClick={submitForm} >Inloggen</div>
               </div>
            </div>
         </section>
   );
}



import React from 'react';
import axios from 'axios';
import { useEffect, useRef,useState } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import {} from '../services/functions'
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import {BASE_URL} from '../services/config';
import {ButtonBack} from '../components/button/button-back';

export const PageTime = () => {
   let navigate: NavigateFunction = useNavigate();
   const { slug } = useParams<{ slug: string }>();
   const [pornstar, setPornstar] = useState<any>([]);   
   const [videos, setVideos] = useState<any>([]);
   const [page, setPage] = useState<Number>(1);
   const [search, setSearch] = useState<any>("");

   useEffect(() => {
      fetchPornstar();
  }, []);

  useEffect(() => {
   if (pornstar.length != 0) {
      fetchVideos();
   }
   
}, [pornstar]);


  const fetchPornstar = async () => {
      try {
          const response = await axios.get(BASE_URL+'api/pornstar.php?slug='+slug);
          var result = response.data.result;
          console.log(response);
          setPornstar(result);
      } catch (error) {
          console.error('Failed to fetch organisations:', error);
      }
  };

  const fetchVideos = async () => {
   console.log(pornstar.id, page);
   try {
       const response = await axios.get(BASE_URL+'api/videos.php?pornstar_id='+pornstar.id+"&page="+page);
       var result = response.data.result;
       console.log(result);
       setVideos(result);
   } catch (error) {
       console.error('Failed to fetch organisations:', error);
   }
};

const changeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
   const value = e.target.value;
   setSearch(value);
   if(page != 1){
      setPage(1);
   }
   

  
};


const nextPage = () => {
   var p2:any = page;
   p2++;
   setPage(p2);
   
   
}


   return (
      <div className="page">
         <section className="title-header">
            <div className="grid">
               <ButtonBack/>
               <h2>Urenregistratie</h2>
            </div>
         </section>
      </div>
   );
}



import React from 'react';
import axios from 'axios';
import { useEffect, useRef,useState } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
//import {getVideoPosterUrl,getPornstarUrl, getVideoPreviewUrl, getVideoUrl, formatNumberOfViews, formatDuration} from '../../services/functions'
import './user-intro.scss';
import { tokenDecoded2, tokenGet, tokenValid, logout} from '../../services/auth';

export const UserIntro = () => {

   const [user, setUser] = useState<any>({});
   
   
   useEffect(() => {
      const tokenData = tokenDecoded2();
      console.log(tokenData);
      if(tokenData){
         setUser(tokenData);
      }
   }, []);

   const doLogout = () => {
      logout();
      
   }

   const openModal = (modal:any) => {
      var event = new CustomEvent("modal", {
         detail: {
           action: "didInitialize",
           payload: 3
         }
       });
       
       window.dispatchEvent(event);
   }

   return (
      <>
         <section className="section-user-intro">
            <div className="grid -flex">
               
               <div className="text">
                  <h1 className="">Goedemorgen, {user.firstname}</h1>
                  <span className="description">Je hebt vandaag <Link to="/projects">2 projecten</Link> op de planning</span>
                  <a href="" className="" onClick={doLogout}>Uitloggen</a>
               </div>
               <div className="image" onClick={() => openModal('modal')}>
                  <img src={user.avatar} alt="" />
               </div>
            </div>
            
            
         </section>
      </>
   );
}




import './modal.scss';


export interface SettingsProps {
    project?: any;
    className?: string;
}

export const Modal = ({ project }: SettingsProps) => {




   return (
      <>

       <div className="modal">
        <div className="modal-box">asdf</div>
       </div>
      </>
   );
}



import React from 'react';
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import {BASE_URL} from '../services/config';
import {UserIntro} from '../components/user/user-intro';
import {Projects} from '../components/projects/projects';
//import {getVideoPosterUrl,getPornstarUrl, getVideoPreviewUrl, getVideoUrl} from '../services/functions'

export const PageOverview = () => {

   return (
      <div className="page">
         <UserIntro/>
         <Projects/>
      </div>
   );
}



import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import {getProjectUrl} from '../services/functions'
import {BASE_URL} from '../services/config';
import { logout} from '../services/auth';
import {ButtonBack} from '../components/button/button-back';

export const PageSettings = () => {

   const [projects, setProjects] = useState<any>([]);

   useEffect(() => {
      fetchProjects();
   }, []);

  
 



  const fetchProjects = async () => {
     
      try {
         const response = await axios.get(BASE_URL+'/projects');
         var results = response.data.results;
         console.log(results);
         setProjects(results);
          
      } catch (error) {
          console.error('Failed to fetch organisations:', error);
      }
  };

   const doLogout = () => {
      logout();  
   }



   

   return (
      <div className="page">
         <section className="title-header">
            <div className="grid">
               <ButtonBack/>
               <h2>Instellingen</h2>
            </div>
         </section>
         <section className="">
            <div className="grid">
               
               <ul>
                  
                     <li className="item">
                        <a href="#" onClick={doLogout}>Uitloggen</a>
                     </li>
             
                  
               </ul>
            </div>
         </section>
      </div>
   );
}



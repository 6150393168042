import React from 'react';
import { useEffect, useState } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import {
   checkV1Token,
   logout,
   tokenDecoded2,
   tokenGet,
   tokenValid,
   updateToken,
} from './services/auth';
import {PageOverview} from './pages/overview';
import {PageProject} from './pages/project';
import {PageProjects} from './pages/projects';
import {PageSchedule} from './pages/schedule';
import {PageSettings} from './pages/settings';
import {PageTime} from './pages/time';
import {PageLogin} from './pages/login';
import {Navigation} from './components/navigation/navigation';
import {Modal} from './components/modal/modal';
import './App.scss';

export const App = () => {
   
   const [tokenValidState, setTokenValidState] = useState(false);
   const [appLoaded, setAppLoaded] = useState(false);
   const timeStart = Date.now();


   useEffect(() => {
   
      const timeEnd = Date.now();
      const timeDiff = timeEnd-timeStart;
      const timeLoad = 0;
      if(timeDiff < timeLoad){
         var timeWait = timeLoad-timeDiff;
      }
      else{
         var timeWait = 0;
      }
      setTimeout(function(){
         setAppLoaded(true);
      },timeWait);

         

      checkLoggedIn();

      
      
   }, []);

   window.addEventListener('loggedIn', (e) => {
      checkLoggedIn()
  });
  window.addEventListener("modal", event => console.log(event));

   const checkLoggedIn = () => {
      const token = tokenGet();
      if (token) {
         setTokenValidState(true);
      }
      else{
         setTokenValidState(false);
      }
   }

   return (
      <div className="App">
         
           
         {tokenValidState ? (

            <>
               <Navigation/>
               <Routes>
                  <Route path="" element={<PageOverview/>} />
                  <Route path="/schedule" element={<PageSchedule/>} /> 
                  <Route path="/projects" element={<PageProjects/>} />  
                  <Route path="/project/:id" element={<PageProject />} />  
                  <Route path="/settings" element={<PageSettings/>} />  
                  <Route path="/time" element={<PageTime />} />         
               </Routes>
            </>

         ) : (

            <PageLogin/>
            
         )}


         <Modal/>
         
         

         <div className={`App-loading ${
                           appLoaded ? '' : '-active'
                       }`}>
            
         </div>
      </div>
   );
}

export default App;

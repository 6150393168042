import React from 'react';
import axios from 'axios';
import { useEffect, useRef,useState } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import './navigation.scss';
import Icon from '../icons/icons';
import { AppData } from '../../services/functions'
import { tokenDecoded2, tokenGet, tokenValid, logout} from '../../services/auth';

export const Navigation = () => {

   const p: any = [
      { name: 'Overzicht', url: '', menu: true, icon: 'home' },
      { name: 'Agenda', url: '/schedule', menu: true, icon: 'calendar' },
      { name: 'Projecten', url: '/projects', menu:true, icon: 'documents' },
      { name: 'Uren', url: '/time', menu: true, icon: 'clock' },
      { name: 'Instellingen', url: '/settings', menu: true, icon: 'settings' },
   ];
   const [dashboardPage, setDashboardPage] = useState<string>('');
   const [pages, setPages] = useState<any>(p);
   let navigate: NavigateFunction = useNavigate();
   const { hash } = useParams<{ hash: string }>();


   useEffect(() => {
      const currentURL = window.location.pathname;

      pages.map((page: any) => {
         if (currentURL.includes(page.url)) {
            setDashboardPage(page.name);
         }
      });

   }, []);


   const [user, setUser] = useState<any>({});
   
   
   useEffect(() => {
      const tokenData = tokenDecoded2();
      console.log(tokenData);
      if(tokenData){
         setUser(tokenData);
      }
   }, []);

   const openModal = (modal:any) => {
      var event = new CustomEvent("modal", {
         detail: {
           action: "didInitialize",
           payload: 3
         }
       });
       
       window.dispatchEvent(event);
   }






   return (
      <>
         <header id="header">
            <div className="grid">
               <div className="user">
                  <div className="image" onClick={() => openModal('modal')}>
                     <img src={user.avatar} alt="" />
                     <p>{user.avatar}</p>
                  </div>
                  <div className="text">
                     <div className="name">{user.firstname}</div>
                     <div className="function">Loodgieter</div>
                  </div>
               </div>
               <div className="menu">
                  <div className="lines">
                     <div className="line" id="line-1"></div>
                     <div className="line" id="line-2"></div>
                     <div className="line" id="line-3"></div>
                  </div>
               </div>
            </div>
         </header>
         <nav className="navigation" id="navigation">
            <Link className="logo"
               to="/"
               onClick={() => setDashboardPage('Overzicht')}
            >
               <div className="grid">
                  <img src={AppData().company.logo} alt="" />
                  <span className="title">{AppData().company.name}</span>
               </div>
            </Link>
            <div className="grid">
               
               <ul className="main-navigation">
               {pages.map(
                  (page: any, i: any) =>
                     page.menu && (
                        <li
                              className={` ${
                                 dashboardPage === page.name ? '-active' : ''
                              }`}
                              key={i}
                        >
                              <Link
                                 to={page.url}
                                 onClick={() => setDashboardPage(page.name)}
                              >
                                 <Icon icon={page.icon} />

                                 <span className="text">{page.name}</span>
                              </Link>
                        </li>
                     ),
                  )}

               </ul>
            </div>
         </nav>
      </>
   );
}



import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import {getProjectUrl} from '../services/functions'
import {BASE_URL} from '../services/config';
import {Projects} from '../components/projects/projects';
import {ButtonBack} from '../components/button/button-back';
export const PageProjects = () => {



  
 





 



   

   return (
      <div className="page">
         <section className="title-header">
            <div className="grid">
               <ButtonBack/>
               <h2>Projecten</h2>
            </div>
         </section>
         <Projects/>
      </div>
   );
}



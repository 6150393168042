
import {BASE_URL} from '../services/config';

export const getProjectUrl = (id:any) => {
    /*var domain = BASE_URL;
    var path = "media/posters/";
    var prefix1 = hash.charAt(0)+hash.charAt(1)+"/";
    var prefix2 = hash.charAt(2)+hash.charAt(3)+"/";
    var extension = ".webp";
    var url = domain+path+prefix1+prefix2+hash+extension;*/
    var url = "/project/"+id;
    return url;
};


export const AppData = () => {
    
    var var2 = {
        'company': {
            'name': "Build Team",
            'logo': "http://localhost:3010/media/images/o6bea8vl8lm9w9ah.svg",
        },
        'user': {
            'name': "Ab",
        }

       
    };
    return var2;
}




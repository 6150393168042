import React from 'react';
import axios from 'axios';
import { useEffect, useRef,useState } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import {BASE_URL} from '../../services/config';
import {getProjectUrl} from '../../services/functions'
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import {ProjectsListItem} from './projectsListItem';
import './projects.scss';
import { tokenDecoded2, tokenGet, tokenValid, authHeader} from '../../services/auth';

export const Projects = () => {

   const [user, setUser] = useState<any>({});
   const [projects, setProjects] = useState<any>([]);
   
   useEffect(() => {
      const tokenData = tokenDecoded2();
      if(tokenData){
         setUser(tokenData);
      }
   }, []);

   useEffect(() => {
      fetchProjects();
   }, []);


   const fetchProjects = async () => {
      try {
         const response = await axios.get(`${BASE_URL}/projects/`, {
            headers: authHeader(),
        });
         var results = response.data.results;

         setProjects(results);
          
      } catch (error) {
          console.error('Failed to fetch organisations:', error);
      }
  };



   return (
      <>
         <section className="section-projects">
            <div className="grid">
               <ul>
                  {projects.map((project: any, i: any) => (
                     <ProjectsListItem project={project}/>
                    
                  ))}
                  
               </ul>
            </div>
         </section>
      </>
   );
}



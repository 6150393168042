import { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';

interface IconProps {
    icon: string;
}

const Icon = ({ icon }: IconProps) => {
    const [loading, setLoading] = useState(false);
    const [svgIcon, setSvgIcon] = useState<any>('');

    useEffect(() => {
        setLoading(true);
        const importIcon = async () => {
            await import('../../assets/images/icons/' + icon + '.svg')
                .then((data) => {
                    setSvgIcon(data.default);
                })
                .catch((err) => console.log(err));
        };
        importIcon();
    }, [icon]);

    if (svgIcon) {
        // Render the imported SVG component
        return <ReactSVG src={svgIcon} />;
    }

    return <></>;
};

export default Icon;


import Icon from '../icons/icons';
import './button.scss';



export const ButtonBack = () => {



   return (
      <>
         <div className="button-back">
            <Icon icon="arrow-left" />
         </div>
      </>
   );
}



import React from 'react';
import axios from 'axios';
import { useEffect, useRef,useState } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import {BASE_URL} from '../../services/config';
import {getProjectUrl} from '../../services/functions'
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';

import './projects.scss';
import { tokenDecoded2, tokenGet, tokenValid, authHeader} from '../../services/auth';


export interface SettingsProps {
    project: any;
    className?: string;
}

export const ProjectsListItem = ({ project }: SettingsProps) => {

   const [user, setUser] = useState<any>({});
   const [projects, setProjects] = useState<any>([]);
   const [projectTimeState, setProjectTimeState] = useState<any>(false);
   const [timeAgo, setTimeAgo] = useState<any>('');
   
   
   useEffect(() => {
      const tokenData = tokenDecoded2();
      if(tokenData){
         setUser(tokenData);
      }
   }, []);

   useEffect(() => {
      fetchProjects();
   }, []);

   useEffect(() => {
    
        if(projectTimeState){
            setTimeout(function(){
                setTimeAgo(timeAgo+1);
            },1000);
        }
        
    }, [timeAgo]);


   const fetchProjects = async () => {

      try {
         const response = await axios.get(`${BASE_URL}/time/project/${project.id}/status`, {
            headers: authHeader(),
        });
        var results = response.data.results;
        
        
        if(results.status == 1){
            setProjectTimeState(true);

           
        }

        var now = Math.floor(new Date().getTime() / 1000);
        var ago = (now-results.start_time);
        setTimeAgo(ago);

          
      } catch (error) {
          console.error('Failed to fetch organisations:', error);
      }
  };

  const projectTimeStop = async (e:any) => {
    e.preventDefault();
    var data = {
        project_id: project.id,
        state: 'stop'
    }
    const response = await axios.post(`${BASE_URL}time`, data, {
        headers: authHeader(),
    });
    setProjectTimeState(false);
  }

  const projectTimeStart = async (e:any) => {
    e.preventDefault();
    var data = {
        project_id: project.id,
        state: 'start'
    }
    const response = await axios.post(`${BASE_URL}time`, data, {
        headers: authHeader(),
    });
    setProjectTimeState(true);
    var now = Math.floor(new Date().getTime() / 1000);
    var ago = (now-response.data.results);
    setTimeAgo(0);
  }



   return (
      <>

        <li key={project.id} className="project-item">
        <Link to={getProjectUrl(project.id)}>
            <div className="side-1">
                <div className="title h4 -bold">{project.name}</div>
                <div className="description -text-soft">{project.description}</div>
                <div className="team">
                {project.users.map((user: any, i: any) => (
                    <><img src={user.avatar} alt="" /></>
                ))}
                </div>
            </div>
            <div className="side-2">
            {projectTimeState ? (
                <>
                    <div className="time">{timeAgo}</div>
                    <div className="timer-button -red" onClick={(e) => projectTimeStop(e)}>
                        Stop
                    </div>
                </>
            ) : (
                <>
                    
                    <div className="timer-button -green" onClick={(e) => projectTimeStart(e)}>
                        Start
                    </div>
                </>
            )}
            </div>
            
        </Link>
        </li>
       
                  

      </>
   );
}


